<template>
  <b-modal
    ref="my-modal"
    title="Deleting confirmation"
    :hide-header-close="true"
    :hide-header="true"
    :hide-footer="true"
    :no-fade="true"
    centered 
    content-class="delete-modal"
    body-class="d-flex flex-column"
  >
    <div class="mb-auto"></div>
    <div class="title">
      <p v-for="(title, index) in titles" :key="title + index">
        {{ title }}
      </p>
    </div>
    <div class="btn-modal">
      <button class="btn btn-info confirm-btn" @click="onConfirm">
        Confirm
      </button>
      <button class="btn btn-light cancel-btn" @click="hideModal">
        Cancel
      </button>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "ModalDelete",
    props: {
      id: {
        type: Number,
        default: -1
      },
      titles: {
        type: Array,
        default: () => ['Are you sure to delete?']
      },
      showNext: {
        type: Number,
        default: -1
      }
    },
    watch: {
      showNext() {
        this.showModal();
      }
    },
    methods: {
      onConfirm() {
        this.$emit('confirm', this.id);
        this.$refs['my-modal'].hide()
      },
      showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      }
    }
  }
</script>

<style scoped>
  .width-70 {
    min-width: 70px;
  }
  .title {
    margin-bottom: 10px;
    margin-top: 50px;
    min-height: 50px;
  }
  .title p {
    margin-bottom: 3px;
  }
  .btn-modal {
    height: 110px;
    margin-top: 20px;
  }
  .btn-modal .btn {
    width: 127px;
    height: 40px;
  }
  .btn.confirm-btn {
    background: rgb(0, 109, 182);
    margin-right: 40px;
  }
  .btn.cancel-btn {
    color: rgb(0, 109, 182);
  }
</style>